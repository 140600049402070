import { useContext } from "react";
import UserContext from "../../context/UserContext";
import UserApi from "../../api/UserApi";
import { useNavigate } from "react-router-dom";

const daysUntilExpiration = expirationDate => {
    const today = new Date();
    const expiryDate = new Date(expirationDate);
      const differenceInTime = expiryDate - today;
    if (differenceInTime < 0) return '만료됨';
    const daysLeft = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    return daysLeft;
  }

const UserCard = () => {
    const navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const handleLogoutBtn = async () => {
        const rslt = await UserApi.logout();
        if (!rslt) return alert('잠시후 다시 시도해주세요.')
        setUser({});
        alert(rslt.message);
    }

    return (
        <div className="user-info pc-only">
            <div className="profile-row">
                <img src="./img/sum_img.png" alt="프로필 이미지" />
                <p onClick={() => navigate('/mypage/info')}>{user.userNicknm || user.userNm} 님</p> 
                <button className="logout-btn" onClick={handleLogoutBtn}>로그아웃</button> 
            </div>
            <div className="stats">
                <div className="stat-item">
                    <small>포인트</small>
                    <strong>{user.point}</strong>
                </div>
                <div className="stat-item">
                    <small>회원상품 잔여일수</small>
                    <strong>{daysUntilExpiration(user.userSlotExpireDate)}</strong>
                </div>
                <div className="stat-item slots">
                    <small>사용 슬롯수</small>
                    <strong>{user.userUsedSlotCnt}<span className="highlight">/{user.userAllowedSlotCnt}</span></strong>
                </div>
            </div>
            <div className="actions">
                <button onClick={() => navigate('/order')}>충전하기 <i className="fas fa-chevron-right"></i></button>
                <button onClick={() => navigate('/mypage/post')} >내글보기 <i className="fas fa-chevron-right"></i></button>
                <button onClick={() => navigate('/')}>전체글보기 <i className="fas fa-chevron-right"></i></button>
            </div>
        </div>
    )
}

export default UserCard;