import React from "react";
import { format, isToday, isThisYear, isThisMonth } from "date-fns";

const TimestampDisplay = ({ timestamp }) => {
  const date = new Date(timestamp);

  let formattedDate;
  if (isToday(date)) {
    formattedDate = format(date, "HH:mm");
  } else if (isThisMonth(date)) {
    formattedDate = format(date, "MM-dd HH:mm");
  } else if (isThisYear(date)) {
    formattedDate = format(date, "yyyy-MM-dd HH:mm");
  } else {
    formattedDate = format(date, "yyyy-MM-dd HH:mm");
  }

  return <>{formattedDate}</>;
};

export default TimestampDisplay;
