import Http from "../util/Http";

class PostApi {
    
    static async getPinnedPosts(searchKeyword = '') {
        const queryParams = new URLSearchParams({
            category : '', 
            page: 1, 
            cnt: 100, 
            isPinned: true, 
            searchKeyword,
            total: false
        }).toString();

        const res = await Http.get(`/posts?${queryParams}`);
        return res || null;
    }

    static async getPosts(category = '', page = 0, cnt = 20, isPinned = false, expireDate = '', searchKeyword = '') {
        const queryParams = new URLSearchParams({
            category, 
            page, 
            cnt, 
            isPinned: isPinned.toString(), 
            expireDate, 
            searchKeyword,
            total: false
        }).toString();

        const res = await Http.get(`/posts?${queryParams}`);
        return res || null;
    }

    static async getPostTotalCount(searchKeyword = '') {
        const queryParams = new URLSearchParams({
            isPinned: false, 
            searchKeyword,
            total: true
        }).toString();
        const res = await Http.get(`/posts?${queryParams}`);
        return res || 0;
    }

    static async getPostFull(postId, isMine = '') {
        const res = await Http.get(`/posts/${postId}?isFull=true&isMine=${isMine}`);
        return res || {};
    }

    static async createPost(postData) {
        const res = await Http.post('/posts', postData);
        return res || null;
    }

    static async updatePost(postId, postData) {
        const res = await Http.put(`/posts/${postId}`, postData);
        return res || null;
    }

    static async deletePost(postId) {
        const res = await Http.delete(`/posts/${postId}`);
        return res || null;
    }

    static async deleteMultiplePosts(postIds) {
        const res = await Http.post('/posts/delete-multiple', { post_ids: postIds });
        return res || null;
    }

    static async updateVisitCount(postId) {
        const res = await Http.post(`/posts/${postId}/visit`);
        return res || null;
    }
}

export default PostApi;
