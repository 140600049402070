import axios from 'axios';

class Http {

    static options = { withCredentials: true };

    static get(url) {
        return axios.get(url, Http.options).then(res => res.data);
    }

    static post(url, data) {
        return axios.post(url, data, Http.options).then(res => res.data);
    }

    static put(url, data) {
        return axios.put(url, data, Http.options).then(res => res.data);
    }

    static delete(url) {
        return axios.delete(url, Http.options).then(res => res.data);
    }
}

export default Http;
