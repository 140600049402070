const MyOrder = () => {
    return (
        <div className="tab-content active">
            <div className="order-history">
                <div className="table-section">
                    <table>
                        <thead>
                            <tr>
                                <th>상품정보</th>
                                <th>주문일자</th>
                                <th>주문금액</th>
                                <th>주문상태</th>
                                <th>만료일</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>카페 회원 DB 수집 프로그램</td>
                                <td>2024-08-25</td>
                                <td>600,000원</td>
                                <td>결제완료</td>
                                <td className="expiration-date red">2025-10-21</td>
                            </tr>
                                <tr>
                                <td>카페 회원 DB 수집 프로그램</td>
                                <td>2024-08-25</td>
                                <td>600,000원</td>
                                <td>결제완료</td>
                                <td className="expiration-date red">2025-10-21</td>
                            </tr>
                                <tr>
                                <td>카페 회원 DB 수집 프로그램</td>
                                <td>2024-08-25</td>
                                <td>600,000원</td>
                                <td>결제완료</td>
                                <td className="expiration-date red">2025-10-21</td>
                            </tr>
                                <tr>
                                <td>카페 회원 DB 수집 프로그램</td>
                                <td>2024-08-25</td>
                                <td>600,000원</td>
                                <td>결제완료</td>
                                <td className="expiration-date red">2025-10-21</td>
                            </tr>
                                <tr>
                                <td>카페 회원 DB 수집 프로그램</td>
                                <td>2024-08-25</td>
                                <td>600,000원</td>
                                <td>결제완료</td>
                                <td className="expiration-date red">2025-10-21</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button className="page-btn first-page"><i className="fas fa-angle-double-left"></i></button>
                        <button className="page-btn prev-page"><i className="fas fa-angle-left"></i></button>
                        <button className="page-btn">1</button>
                        <button className="page-btn">2</button>
                        <button className="page-btn">3</button>
                        <button className="page-btn">4</button>
                        <button className="page-btn">5</button>
                        <button className="page-btn">6</button>
                        <button className="page-btn">7</button>
                        <button className="page-btn">8</button>
                        <button className="page-btn">9</button>
                        <button className="page-btn">10</button>
                        <button className="page-btn next-page"><i className="fas fa-angle-right"></i></button>
                        <button className="page-btn last-page"><i className="fas fa-angle-double-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MyOrder