import { Link, useNavigate } from 'react-router-dom';
const linkActiveClass = to => {
    if (to === '/') {
        if (!window.location.hash) return 'active';
        if (window.location.hash.match('/post')) return 'active';
    }
    return window.location.hash === `#${to}` ? 'active' : '';
}

const Header = props => {
    const navigate = useNavigate();
    return (
        <div class="gnb">
            <div class="logo"><img src="/static/img/about/logo.png" /></div>
            <nav class="menu">
                <ul>
                    <li className={linkActiveClass('/')}><Link to="/">대형컨설팅</Link></li>
                    <li className={linkActiveClass('/placeRank')}><Link to="/placeRank">플레이스 순위 체크</Link></li>
                    <li className={linkActiveClass('/shoppingRank')}><Link to="/shoppingRank">쇼핑 순위 체크</Link></li>
                    <li className={linkActiveClass('/placeOnce')}><Link to="/placeOnce">플레이스 1회 순위체크</Link></li>
                    <li className={linkActiveClass('/shoppingOnce')}><Link to="/shoppingOnce">쇼핑 1회 순위체크</Link></li>
                    <li className={linkActiveClass('/order')}><Link to="/order">회원상품결제</Link></li>
                </ul>
            </nav>
            <div class="hamburger-menu">
                <i class="fas fa-bars"></i>
            </div>
        </div>
    )
}

export default Header;