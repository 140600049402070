
import { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Http from '../../../util/Http';
import UserContext from '../../../context/UserContext';

const Login = props => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();
    const [loginId, setLoginId] = useState('');
    const [loginPw, setLoginPw] = useState('');

    const handleIdInput = e => setLoginId(e.target.value);
    const handlePwInput = e => setLoginPw(e.target.value); 

    const handleLoginClick = async () => {
        const res = await Http.post('/user/login', {username : loginId, password: loginPw});
        if(res.userInfo) {
            setUser(res.userInfo);
            navigate('/')
        }
    }

    const handleRegisterClick = () => {
        navigate('/register')
    }

    return (
        <>
            <header className="login-header">
                <h1>로그인</h1>
                <p>에이치마켓팅에 오신것을 환영합니다.</p>
            </header>
            <div className="login-container_in">
                <div className="login-form-container">
                    <div className="login-form">
                        <div className="form-group">
                            <label for="username">아이디</label>
                            <input type="text" id="username" placeholder="아이디" value={loginId} onChange={handleIdInput} />
                        </div>
                        <div className="form-group">
                            <label for="password">비밀번호</label>
                            <input type="password" id="password" placeholder="비밀번호" value={loginPw} onChange={handlePwInput} />
                        </div>
                        <button className="login-btn" onClick={handleLoginClick}>로그인</button>
                        <div className="options">
                            <div className="checkbox-group">
                                <input type="checkbox" id="remember-id" />
                                <label for="remember-id">아이디저장</label>
                            </div>
                            <div className="find-options">
                                <Link to="/register">아이디 찾기</Link> / <Link to="/findInfo">비밀번호 찾기</Link>
                            </div>
                        </div>
                        <div className="signup-message">
                            <p>아직 회원이 아니신가요?</p>
                            <p>회원가입하고 회원만의 혜택을 받으세요!</p>
                        </div>
                        <button type="button" className="signup-btn" onClick={handleRegisterClick}>회원가입</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login;