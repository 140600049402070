import { useState } from "react";
const DebounceBtn = props => {
    const { className, onClick } = props;
    const [disabled, setDisabled] = useState(false)
    const handleBtnClick = () => {
        if (disabled) return;
        setDisabled(true)
        onClick();
        setTimeout(() => { setDisabled(false) }, 2000);
    }
    return (
        <button className={className} onClick={handleBtnClick}>
            {props.children}
        </button>
    )
}

export default DebounceBtn;