import RankBase from "../RankBase";

const Notice = () => {
    return (
        <div class="notice">
            <ul>
                <li>- 플레이스 URL주소는 https://m.place.naver.com/~ 로 시작되는 전체URL주소를 넣어주세요.(네이버모바일에서 검색)</li>
                <li>- 플레이스 URL주소 입력시 업체명이 변경 되어도 순위체크가 가능합니다.</li>
                <li>- 업체명은 띄어쓰기까지 정확하게 입력시 순위체크가 가능합니다.(네이버 플레이스와 동일한 상호명)</li>
                <li>- 매일 순위 체크는 플레이스 반영 후 13시 10분 부터 당일 마감까지 랜덤으로 순위를 업데이트 진행 합니다.</li>
                <li>- 2일간 순위가 체크되지 않는 슬롯은 자동 삭제 됩니다.</li>
                <li>- 최근 3개월 내역까지 저장이되며, 3개월 경과건은 순차적으로 삭제 될 수 있습니다.</li>
            </ul>
        </div>
    )
}

const PlaceRank = props => {
    return (
        <RankBase notice={Notice}  />
    )
}

export default PlaceRank;
