import PopupBase from "./PopupBase";
import SelectGroup from "../group/SelectGroup";
import GroupApi from "../../api/GroupApi";
import SlotApi from "../../api/SlotApi";
import DebounceBtn from "../common/DebounceBtn";

import { useState } from 'react';


const GroupPop = props => {
    const { selectedSlots=[], closed = () => {} } = props;
    const [ selectedGroupId, setSelectedGroupId ] = useState('');
    const [ groupNm , setGroupNm ] = useState('');

    const handleGroupNmInput = e => {
        setGroupNm(e.target.value);
    }

    const handleUpdateBtn = async () => {
         await SlotApi.updateSlotsGroup(selectedSlots.map(slot => slot.slotId), selectedGroupId)
         closed(true);        
    }

    const handleCreateBtn = async () => {
        await GroupApi.createGroup({ groupNm });
        closed(true);        
    }

    return (
        <PopupBase closed={closed}>
            { selectedSlots.length
                ? 
                <div className="group-info">
                    <h3>그룹정보 변경</h3>
                    <div className="group-row">
                        <SelectGroup selectCallback={setSelectedGroupId} />
                        <button className="update-btn" onClick={handleUpdateBtn}>수정</button>
                    </div>
                </div>
                :
                <div className="group-create">
                    <h3>그룹생성</h3>
                    <div className="group-row">
                        <input type="text" placeholder="그룹명" value={groupNm} onChange={handleGroupNmInput} />
                        {/* <input type="text" placeholder="메모" /> */}
                        <DebounceBtn className="create-btn" onClick={handleCreateBtn}>생성</DebounceBtn>
                    </div>
                </div>
            }
        </PopupBase>
    )
}

export default GroupPop;