
import { useState } from 'react';
import UserApi from '../../../api/UserApi';
import { useNavigate } from 'react-router-dom';

const Register = props => {

    const navigate = useNavigate();

    const [user, setUsert ] = useState({
        userPw : '',
        userConfirmPw : '',
    });

    const [isValidId, setIsValidId] = useState(false);

    const handleUserData = e => {
        const { name, value } = e.target;
        const newUser = {...user};
        newUser[name] = value;
        setUsert(newUser);
    }

    const handleIdDuplClick = async e => {
        const res = await UserApi.duplCheck(user.userId);
        const msg = res.valid ? '사용 가능한 아이디입니다.' : '사용할 수 없는 아이디입니다.';
        alert(msg);
        setIsValidId(res.valid);
    }

    const handleCheckEmailClick = e => {
        UserApi.emailCheck(user.userEmail).then(res => {
            if (res.code === 'success') return;
            alert('이메일 전송에 실패하였습니다. 잠시후 다시 시도해주세요.')
        });
        alert('메일 전송에 1~3분 정도 소요됩니다.');
    }

    const beforeRegisterCheck = () => {
        if (!isValidId) return false;
        if (!user.userId || !user.userNm
            || !user.userPw || !user.userConfirmPw 
            || !user.userEmail || !user.userEmailValid 
            || !user.userPhone) return false;
        if (user.userPw !== user.userConfirmPw) return false;
        return true;
    }

    const handleRegisterClick = async e => {
        e.preventDefault();
        const res = await UserApi.join(user);
        if (res && res.complete === true) {
            alert('회원가입을 축하합니다.');
            navigate('/login')
        } else {
            alert('회원가입에 실패하였습니다. 입력하신 정보나 이메일 인증 번호를 다시 확인해주세요.');
        }
    }

    return (
        <>
            <header className="signup-header">
                <h1>회원가입</h1>
                <p>마케팅광장에 오신것을 환영합니다.</p>
		    </header>
            <div className="signup-container">
                <div className="signup-form-container">
                    <form className="signup-form">
                        <div className="form-group">
                            <label for="username">아이디</label>
                            <div className="input-group">
                                <input type="text" id="username" placeholder="아이디" 
                                        name="userId" value={user.userId} onChange={handleUserData} 
                                />
                                <button type="button" className="check-btn" onClick={handleIdDuplClick}>중복확인</button>
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="password">비밀번호</label>
                            <input type="password" id="password" placeholder="비밀번호" 
                                    name="userPw" value={user.userPw} onChange={handleUserData} 
                            />
                        </div>
                        <div className="form-group narrow-gap">
                            <label for="confirm-password" className="sr-only">비밀번호 확인</label>
                            <input type="password" id="confirm-password" placeholder="비밀번호 확인" 
                                    name="userConfirmPw" value={user.userConfirmPw} onChange={handleUserData} 
                            />
                            {user.userPw.length < 8 && <p className="input-hint">비밀번호는 8자리 이상 입력하세요.</p>}
                            {(user.userPw.length < 8 && user.userPw !== user.userConfirmPw) && <br/>}
                            {user.userPw !== user.userConfirmPw && <p className="input-hint">비밀번호는 8자리 이상 입력하세요.</p>}
                        </div>
                        <div className="form-group">
                            <label for="name">이름</label>
                            <input type="text" id="name" placeholder="이름" 
                                    name="userNm" value={user.userNm} onChange={handleUserData} 
                            />
                        </div>
                        <div className="form-group">
                            <label for="email">이메일</label>
                            <div className="input-group">
                                <input type="email" id="email" placeholder="korea@naver.com" 
                                        name="userEmail" value={user.userEmail} onChange={handleUserData} 
                                />
                                <button type="button" className="verify-btn" onClick={handleCheckEmailClick}>인증하기</button>
                            </div>
                        </div>
                        <div className="form-group">
                            <label for="phone">휴대전화번호</label>
                            <input type="text" id="phone" placeholder="01012345678 (-제외)" 
                                name="userPhone" value={user.userPhone} onChange={handleUserData}
                            />
                        </div>
                        <div className="form-group checkbox-group">
                            <input type="checkbox" id="privacy-policy" />
                            <label for="privacy-policy">개인정보 수집동의</label>
                        </div>
                        <div className="privacy-box">
                            개인정보 수집동의 내용이 들어갈 예정입니다.
                        </div>
                        <button type="submit" className="submit-btn" onClick={handleRegisterClick}>가입하기</button>
                    </form>
                </div>
            </div>
        </>
    )
}

export default Register;