import React, { createContext, useState, useEffect } from 'react';
import UserApi from '../api/UserApi';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({});

  useEffect(() => {
    UserApi.getUserLoginInfo().then(rslt => {
      if (rslt && rslt.user) setUser(rslt.user);
    })
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
