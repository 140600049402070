import { useContext } from "react";
import RightBanner from "../banner/RightBanner";
import UserCard from "./UserCard";
import UserContext from "../../context/UserContext";
import LoginNavigater from "./LoginNavigater";

const RightSidebar = props => {
    const { user } = useContext(UserContext);
    console.log('user>>>>', user);
    return (
        <div className="right-sidebar">
            {user.userId ? <UserCard />  : <LoginNavigater />}
            <RightBanner />
        </div>
    )
}

export default RightSidebar;