const WEEK_DAYS = ['일', '월', '화', '수', '목', '금', '토'];

function formatDateTime(dateString) {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const dayOfWeek = WEEK_DAYS[date.getDay()];
    return [`${month}-${day}`, dayOfWeek];
}

function formatDateYYYMMDD(timestamp) {
    if (!timestamp) return '';

    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

function daysUntilExpire(expireDate) {
    const today = new Date();
    const targetDate = new Date(expireDate);
    const diffTime = targetDate - today;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

function addDaysToDate(date, daysToAdd) {
    const resultDate = new Date(date);
    resultDate.setDate(resultDate.getDate() + Number(daysToAdd));
    return resultDate;
}

function getLatestDate(dates) {
    const timestamps = dates.map(date => new Date(date).getTime());
    const maxTimestamp = Math.max(...timestamps);
    return new Date(maxTimestamp);
}

export { formatDateTime,formatDateYYYMMDD, daysUntilExpire, addDaysToDate, getLatestDate };