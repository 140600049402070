import Http from '../util/Http';

class SlotApi {

    static getSelectParams(page, count, search = '', groupId = '') {
        const params = {
            page,
            count,
            search,
            isTotalCnt : !page && !count
        };
        if (groupId) {
            params.groupId = groupId;
        }
        return new URLSearchParams(params).toString();
    }

    static async getSlots(page = 1, count = 10, search, groupId) {
        const queryString = this.getSelectParams(page, count, search, groupId);
        const res = await Http.get(`/slot?${queryString}`);
        return res || [];
    }

    static async getSlotTotalCnt(search, groupId) {
        const queryString = this.getSelectParams(0, 0, search, groupId);
        const res = await Http.get(`/slot?${queryString}`);
        return res || [];
    }

    // Create a new slot
    static async createSlot(slotData) {
        const res = await Http.post('/slot', slotData);
        return res || null;
    }

    // Update multiple slots
    static async updateSlots(slots) {
        const res = await Http.put('/slot', { slots });
        return res || null;
    }

    // Update multiple slots
    static async updateSlotsGroup(slotIds, groupId) {
        const res = await Http.put('/slot/group', { slotIds, groupId });
        return res || null;
    }

    // Delete multiple slots by their IDs
    static async deleteSlots(slotIds) {
        const res = await Http.post('/slot/delete', { slotIds });
        return res || null;
    }
}

export default SlotApi;
