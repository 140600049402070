import { useState, useEffect, useContext } from "react"
import UserContext from "../../../context/UserContext";
import { formatDateYYYMMDD, daysUntilExpire, addDaysToDate, getLatestDate } from "../../../util/dateUtil";
import Payment from "../../../util/Payment";

const COST_OF_ONE_DAY_PER_100_CNT = 700;

const SLOT_OPTIONS = [
    { prodId: '', value: '50', label: '슬롯 50개', disabled: false, price: 5900 },
    { prodId: '', value: '100', label: '슬롯 100개', disabled: false, price: 10000 },
    { prodId: '', value: '200', label: '슬롯 200개', disabled: false, price: 20000 },
    { prodId: '', value: '300', label: '슬롯 300개', disabled: false, price: 30000 },
    { prodId: '', value: '500', label: '슬롯 500개', disabled: false, price: 50000 },
    { prodId: '', value: '1000', label: '슬롯 1000개', disabled: false, price: 100000 }
]

const getSlotOptions = (userSlotCnt, isIncrease = true) => {
    for (let i = 0; i < SLOT_OPTIONS.length; i++) {
        const option = SLOT_OPTIONS[i];
        option.disabled = option.value < userSlotCnt;
        if (!isIncrease) option.disabled = !option.disabled;
    }
    return SLOT_OPTIONS;
}

const getDefaultSlotOption = curCnt => {
    if (!curCnt) return SLOT_OPTIONS[0];
    return SLOT_OPTIONS.filter(slot => slot.value == curCnt)[0];
}

const EXTEND_OPTIONS = [
    { prodId: '', value: '0', label: '연장 안함'},
    { prodId: '', value: '30', label: '30일 연장'},
    { prodId: '', value: '60', label: '60일 연장'},
    { prodId: '', value: '90', label: '90일 연장'},
]

const getDefaultExtendOption = extendDate => {
    if (!extendDate || extendDate < new Date()) return EXTEND_OPTIONS[1];
    return EXTEND_OPTIONS[0];
}

const OptionSelector = ({ user = {}, curOption, onChange, type='count', isIncrease }) => {
    const options = type === 'count' ? getSlotOptions(user.userAllowedSlotCnt, isIncrease) : EXTEND_OPTIONS;
    return (
        <div class="slot-options">
            {options.map(option => 
                <label class="slot-option" key={type + option.value}>
                    <input type="radio" name={type} 
                        value={option.value}
                        checked={curOption === option}
                        onChange={() => onChange(option)}
                        disabled={option.disabled}
                    />
                    {option.label}
                </label>
            )}   
        </div>
    )
}

const SlotCountChange = () => {
    const { user } = useContext(UserContext);
    const [curState, setCurState] = useState('increase');
    const [curSlotOption, setCurSlotOption] = useState(getDefaultSlotOption(user.userAllowedSlotCnt));
    const [curExtendOption, setCurExtendOption] = useState(getDefaultExtendOption(user.userSlotExpireDate));
    const [finalSlotCnt, setFinalSlotCnt] = useState(0);
    const [finalExpireDate, setFinalExpireDate] = useState(0);
    const [cost, setCost] = useState(0);
    const isIncrease = () => curState === 'increase';

    const handleIncreaseBtn = () => {

        if (cost === 0) return alert('결제 금액이 0원입니다. 상품을 다시 선택해주세요.');

        const payUser = {
            userEmail : user.userEmail,
            userNm : user.userNm,
            userPhone : user.userPhone,
        };

        let newTitle = '';
        let extendDays = 0;

        if (curSlotOption.value == user.userAllowedSlotCnt && curExtendOption.value != 0) {
            newTitle = `만료일 연장하기(${curExtendOption.value})`;
        } else {
            if (curSlotOption.value != user.userAllowedSlotCnt) {
                newTitle = `${curSlotOption.label} 늘리기`;
                extendDays = Math.max(daysUntilExpire(user.userSlotExpireDate), 0);
            }
            if (curExtendOption.value != 0) {
                newTitle += ` 및 연장하기`;
                extendDays += Number(curExtendOption.value);
            }
            newTitle += `(${extendDays})`;
        }

        const data ={
            prodId : curSlotOption.prodId,
            prodTitle : newTitle,
            prodDisc: 0,
            prodPrice: cost
        };

        Payment.openPaymentPage(data, payUser, '/order/complete');
    }

    const handleDecreaseBtn = () => {
        if (curSlotOption.value >= user.userAllowedSlotCnt)
            alert('현재 슬롯보다 적게만 줄일 수 있습니다.');
        const confirmed = window.confirm('정말 줄이시겠습니까? 줄이기하신 슬롯은 환불 되지 않습니다.');
    }

    useEffect(() => {
        setCurSlotOption(getDefaultSlotOption(user.userAllowedSlotCnt));
        setCurExtendOption(getDefaultExtendOption(user.userSlotExpireDate));
    }, [user, curState])

    useEffect(() => {
        if (!user.userAllowedSlotCnt || !curSlotOption || !curExtendOption) return;
        
        setFinalSlotCnt(curSlotOption.value);
        const curExpireDate = getLatestDate([user.userSlotExpireDate, new Date()]);
        setFinalExpireDate(addDaysToDate(curExpireDate, curExtendOption.value));

        let remainDays = Math.max(daysUntilExpire(user.userSlotExpireDate), 0); 
        const curPrice = SLOT_OPTIONS.filter(o => o.value == user.userAllowedSlotCnt)[0].price;
        const newPrice = SLOT_OPTIONS.filter(o => o.value == curSlotOption.value)[0].price; 
        let newCost = (remainDays/30) * (newPrice - curPrice);
        if (curExtendOption.value > 0) newCost += (curSlotOption.price * curExtendOption.value / 30);
        setCost(newCost);
    }, [curSlotOption, curExtendOption]);

    return (
        <div class="slot-container">
            <div class="tab-menu">
                <button class={`tab-btn ${isIncrease() ? 'active' : ''}`} onClick={() => setCurState('increase')}>슬롯 늘리기</button>
                <button class={`tab-btn ${!isIncrease() ? 'active' : ''}`} onClick={() => setCurState('decrease')}>슬롯 줄이기</button>
            </div>
            <div class="slot-selection">
                <h3>슬롯 희망 갯수</h3>
                <OptionSelector user={user} curOption={curSlotOption} onChange={setCurSlotOption} isIncrease={isIncrease()} />
                <h3>만료일 연장</h3>
                {isIncrease() && <OptionSelector type={'extend'} curOption={curExtendOption} onChange={setCurExtendOption} />}
                <table class="slot-table">
                    <tr>
                        <th>최종 슬롯 개수</th>
                        <td>{finalSlotCnt}</td>
                    </tr>
                    <tr>
                        <th>최종 만료일</th>
                        <td>{formatDateYYYMMDD(finalExpireDate)}</td>
                    </tr>
                    {isIncrease() && <tr>
                        <th>추가 예상 금액</th>
                        <td>{cost}원</td>
                    </tr>}
                </table>
                {!isIncrease() && <>
                    { Math.max(user.userUsedSlotCnt - Number(curSlotOption.value), 0) > 0  && 
                    <p class="txt18">
                        * 사용슬롯을 줄이시려면 기존에 사용승인 슬롯 {user.userUsedSlotCnt}개에서&nbsp; 
                        {Math.max(user.userUsedSlotCnt - Number(curSlotOption.value), 0)}개를 삭제하셔야 합니다.</p>
                    }
                    <p class="txt18 mt10">* 줄이기 및 삭제한 슬롯은 복원/환불 되지 않습니다.</p>
                </>}
            </div>

            <div class="payment-section">
                {isIncrease() 
                    ? <button class="payment-btn" onClick={handleIncreaseBtn} disabled={cost === 0}>결제하기</button>
                    : <button class="payment-btn" onClick={handleDecreaseBtn}>줄이기</button>
                }
            </div>
        </div>
    )
}

export default SlotCountChange;