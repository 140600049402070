import '../../css/onceSearch.css'
import SearchApi from '../../api/SearchApi';

import { useState } from 'react';

const inputHandlerBuilder = setState => {
    return e => setState(e.target.value);
}

const defaultRankList = ['-', '-', '-']

const Search = props => {
    const { api, targetPlaceholder = '상품명' } = props;
    const [keyword, setKeyword] = useState('');
    const [url, setUrl] = useState('');
    const [prodNm, setProdNm] = useState('')
    const [isLoading, setIsLoading] = useState(false);
    const [rankList, setRankList] = useState(defaultRankList);

    const handleSearchClick = async e => {
        e.preventDefault();
        if (!keyword || !prodNm) return alert('검색어를 입력하셔야 합니다.')
        setIsLoading(true);
        const res = await api(keyword, prodNm);
        setRankList(res || defaultRankList);
        setIsLoading(false);
    }

    const handleConsultClick = () => {
        const url = "https://open.kakao.com/o/sP2q7tGg";
        const newWindow = window.open(url, "_blank");

        if (newWindow) {
            newWindow.focus();
        } else {
            alert("팝업 차단 설정이 활성화되어 새 창을 열 수 없습니다.");
        }
    }

    return (
        <div className="center-content once-search">
            <div className="search-section">
                <div className="input-icon">
                    <i className="fas fa-search icon"></i>
                    <input type="text" placeholder="검색어" className="search-keyword" 
                        value={keyword} onChange={inputHandlerBuilder(setKeyword)} 
                    />
                </div>
                <input type="text" placeholder={targetPlaceholder} className="search-url"
                        value={prodNm} onChange={inputHandlerBuilder(setProdNm)}
                    />
                <button className="search-btn" onClick={handleSearchClick} disabled={isLoading}>검색</button>
            </div>
            <div className="table-section pc-table">
                <div className="table-controls">
                    <table className="custom-table">
                        <thead>
                            <tr>
                                <th>검색키워드</th>
                                <th>순위</th>
                            </tr>    
                        </thead>
                        <tbody>
                            <tr>
                                <td>{rankList[0]}</td>
                                <td>{rankList[2]}</td>
                            </tr>
                        </tbody>    
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Search;