import { useEffect, useState } from 'react';
import PostApi from '../../../api/PostApi';
import { useParams, useNavigate } from 'react-router-dom';
import TimestampDisplay from '../../common/TimestampDisplay';
import DOMPurify from 'dompurify';

const PostDetail = props => {
    const navigate = useNavigate()
    const { postId } = useParams();
    const [post, setPost] = useState(null);

    const handleDeleteBtn = async () => {
        const confirmed = window.confirm("삭제하시겠습니까?");
        if (!confirmed) return;
        await PostApi.deletePost(postId);
        navigate('/?reload=true');
    }

    useEffect(() => {
        PostApi.getPostFull(postId).then(rslt => {
            if (rslt.post) setPost(rslt.post);
            else alert('존재하지 않은 게시물입니다.');
        });
    }, [postId]);

    if (!post || !post.post_id) return '';

    return (
        <div class="summary-container write_end_con">
            <div class="summary-header">
                <h2>{post.post_title}</h2>
            </div>
            <div class="meta-info">
                <p class="author">글쓴이 : {post.user_nicknm}</p>
                <p class="date"><TimestampDisplay timestamp={post.post_reg_date} /></p>
            </div>

            <table class="info-table pc-only">
                <tr>
                    <th>회사명</th>
                    <td>{post.post_company || '-'}</td>
                    <th>담당자명</th>
                    <td>{post.post_contact || '-'}</td>
                </tr>
                <tr>
                    <th>연락처</th>
                    <td>{post.post_contact_phone || '-'}</td>
                    <th>이메일</th>
                    <td>{post.post_contact_email || '-'}</td>
                </tr>
                <tr>
                    <th>카카오톡</th>
                    <td>{post.post_contact_kakao || '-'}</td>
                    <td colspan="2"></td>
                </tr>
            </table>
            <div class="description"
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }}
            >
            </div>
            <div class="banner-section">
                <div class="banner">배너가 들어갈 자리입니다.</div>
                <div class="banner">배너가 들어갈 자리입니다.</div>
            </div>
            <div class="button-section">
                <button class="list-btn" onClick={() => navigate('/')}>목록</button>
                <button class="list-btn2" onClick={() => navigate(`/post/write/${postId}`)}>수정</button>
                <button class="list-btn3" onClick={handleDeleteBtn}>삭제</button>
                <button class="submit-btn" onClick={() => navigate('/post/write')} >글쓰기</button>
            </div>
        </div>
    )

}

export default PostDetail;