import { formatDateTime } from "../../../util/dateUtil";

const RankTd = props => {
    const { ranks } = props;
    if (!ranks) return (
        <td colspan="10"></td>
    );

    const devidedList = [];
    let devidedListIdx = -1;
    for (let i = 0; i < ranks.length; i++) {
        if (i % 10 === 0) {
            devidedListIdx++;
            devidedList[devidedListIdx] = [];
        }
        devidedList[devidedListIdx].push(ranks[i]);
    }

    return (
        <td colspan="10">
            {devidedList.map(ranks => (
                <div className="rank-grid">
                    {ranks.map(({rankDate, rankRank}) => {
                        const [ date, day ] = formatDateTime(rankDate);
                        return (
                            <div className="rank">
                                <p>{date}<br />({day})</p>
                                <p className="rank-value">{rankRank}위</p>
                            </div>
                        )
                    })}
                </div>    
            ))}
        </td>
    )
}

export default RankTd