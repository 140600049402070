const MyPost = () => {
    return (
        <div className="tab-content active">
            <div className="posts-history">
                <table className="posts-table"> 
                    <thead>
                        <tr>
                            <th>카테고리</th>
                            <th>제목</th>
                            <th>작성일</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>카테고리 제목</td>
                            <td>이곳에는 제목이 들어갑니다.</td>
                            <td>2024.10.01</td>
                        </tr>
                        <tr>
                            <td>카테고리 제목</td>
                            <td>이곳에는 제목이 들어갑니다.</td>
                            <td>2024.10.01</td>
                        </tr>
                        <tr>
                            <td>카테고리 제목</td>
                            <td>이곳에는 제목이 들어갑니다.</td>
                            <td>2024.10.01</td>
                        </tr>
                        <tr>
                            <td>카테고리 제목</td>
                            <td>이곳에는 제목이 들어갑니다.</td>
                            <td>2024.10.01</td>
                        </tr>
                        <tr>
                            <td>카테고리 제목</td>
                            <td>이곳에는 제목이 들어갑니다.</td>
                            <td>2024.10.01</td>
                        </tr>
                        <tr>
                            <td>카테고리 제목</td>
                            <td>이곳에는 제목이 들어갑니다.</td>
                            <td>2024.10.01</td>
                        </tr>
                    </tbody>
                </table>
                <div className="pagination">
                    <button className="page-btn first-page"><i className="fas fa-angle-double-left"></i></button>
                    <button className="page-btn prev-page"><i className="fas fa-angle-left"></i></button>
                    <button className="page-btn">1</button>
                    <button className="page-btn">2</button>
                    <button className="page-btn">3</button>
                    <button className="page-btn">4</button>
                    <button className="page-btn">5</button>
                    <button className="page-btn next-page"><i className="fas fa-angle-right"></i></button>
                    <button className="page-btn last-page"><i className="fas fa-angle-double-right"></i></button>
                </div>
            </div>
        </div>
    )
}

export default MyPost;