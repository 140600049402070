import { useState, useEffect } from 'react'
import UserApi from '../../../api/UserApi';
import Payment from '../../../util/Payment';

const MIN_CHARGE_POINT = 5000;
const ChargePoint = () => {
    const [user, setUser] = useState({});
    const [chargePoint, setChargePoint] = useState(MIN_CHARGE_POINT);

    const handleChargePointInput = e => {
        setChargePoint(e.target.value);
    }

    const handleChargeBtn = () => {
        const payUser = {
            userEmail : user.userEmail,
            userNm : user.userNm,
            userPhone : user.userPhone,
        }
        
        const amount = Number(chargePoint);

        // if (amount < MIN_CHARGE_POINT || amount % 1000 !== 0) {
        //     return alert("충전 불가능한 금액입니다.")
        // }

        const data = {
            prodId : '10000',
            prodTitle : '포인트 충전',
            prodDisc : 0,
            prodPrice : amount
        };
        Payment.openPaymentPage(data, payUser, '/point/charge/complete');
    }

    useEffect(() => {
        UserApi.getMyInfo().then(data => {
            console.log('data>>>', data);
            setUser(data.user);
        })
    }, [])

    return (
        <>
            <div class="points-summary">
                <div class="points-container">
                    <div class="current-points">
                        <i class="fas fa-coins"></i>
                        <span>잔여포인트</span>
                        <strong>{user.point}</strong>
                    </div>
                    <div class="charge-section">
                        <input type="text" class="number-input" value={chargePoint} onChange={handleChargePointInput} />
                        <button class="charge-btn" onClick={handleChargeBtn}>
                            <i class="fas fa-plus-circle"></i>
                            충전하기
                        </button>
                    </div>
                </div>
            </div>
            <p class="point_txt">* 최소 5,000원부터 충전 가능합니다.</p>
        </>
    )
}

export default ChargePoint;