import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Editor from '../../common/Editor';
import PostApi from '../../../api/PostApi';
import { POST_CLASS } from '../../../constant/PostClass';

function base64ToFile(base64Data) {
    const [fileInfo, encodingString] = base64Data.split(',');
    
    const mimeType = fileInfo.split(':')[1].split(';')[0];
    const fileName = `${Date.now()}_image.${mimeType.split('/')[1]}`;
    
    const byteString = atob(encodingString);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(arrayBuffer);

    for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
    }

    return new File([intArray], fileName, { type: mimeType });
}

const PostWrite = () => {
    // Initialize state based on the database schema
    const navigate = useNavigate();
    const { postId = '' } = useParams();
    const [postClass, setPostClass] = useState(POST_CLASS.basic);
    const [postCategory, setPostCategory] = useState('');
    const [postTitle, setPostTitle] = useState('');
    const [postCompany, setPostCompany] = useState('');
    const [contact, setContact] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [contactKakao, setContactKakao] = useState('');
    const [content, setContent] = useState('');

    const handleInputChange = (event, setter) => setter(event.target.value);

    const handleEditorChange = (event, editor) => {
        setContent(editor.getData());
    };

    const uploadImagesAndReplaceInContent = async () => {
        const imageTags = content.match(/<img[^>]+src="([^">]+)"/g);
        const base64Images = imageTags ? imageTags.map(tag => tag.match(/src="([^">]+)/)[1]) : [];
        const uploadedImageUrls = await Promise.all(
            base64Images.map(async (base64, index) => {                
                const file = base64ToFile(base64);
                const formData = new FormData();
                formData.append('image', file);
                const response = await fetch('/uploads', {
                    method: 'POST',
                    body: formData,
                });
                const result = await response.json();
                return result.imageUrl;
            })
        );

        let updatedContent = content;
        base64Images.forEach((base64, index) => {
            updatedContent = updatedContent.replace(base64, uploadedImageUrls[index]);
        });
        return updatedContent
    };

    const handleSubmit = async () => {
        const updatedContent = await uploadImagesAndReplaceInContent();
        const newPost = {
            postClass,
            postCategory,
            postTitle,
            postCompany,
            postContact: contact,
            postContactPhone: contactPhone,
            postContactEmail: contactEmail,
            postContactKakao: contactKakao,
            content: updatedContent
        }

        let rslt;
        if (postId) rslt = await PostApi.updatePost(postId, newPost);
        else rslt = await PostApi.createPost(newPost);

        if (rslt.code === 'fail') {
            alert(rslt.message || '작업에 실패하였습니다.');
        } else {
            // TODO 포인트 차감된거 리프레쉬 되게 해야됨.. 그전에 로그인 정보 계속 유지되게 해야됨  
            navigate('/');
        }
    };

    useEffect(() => {
        if (!postId) return;
        PostApi.getPostFull(postId, true).then(data => {
            if (!data.post) return alert('수정할 권한이 없습니다.');
            const { post } = data;
            setPostTitle(post.post_title);
            setPostCompany(post.post_company);
            setContact(post.post_contact);
            setContactPhone(post.post_contact_phone);
            setContactEmail(post.post_contact_email);
            setContactKakao(post.post_contact_kakao);
            setContent(post.content);
        });
    }, [])

    return (
        <div className="center-content">
            {!postId && <div className="post-type-section">
                <div className="post-type-container">
                    <div className="post-type-header">
                        <strong>+ 게시물형태<br />(회원옵션)</strong>
                    </div>
                    <div className="post-type-options">
                        <label>
                            <input
                                type="radio"
                                name="post-type"
                                value="default"
                                checked={postClass === POST_CLASS.basic}
                                onChange={() => setPostClass(POST_CLASS.basic)}
                            />
                            사용안함 <span>일반게시물 (포인트 -1,000차감)</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="post-type"
                                value="highlight"
                                checked={postClass === POST_CLASS.bold}
                                onChange={() => setPostClass(POST_CLASS.bold)}
                            />
                            굵게표시 <span>게시물제목을 굵게표시 (포인트 -2,000차감)</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="post-type"
                                value="urgent"
                                checked={postClass === POST_CLASS.urgent}
                                onChange={() => setPostClass(POST_CLASS.urgent)}
                            />
                            <span className="urgent">급해요</span> <span>아이콘과 게시물이 눈에 띄는 색상으로 표시 (포인트 -3,000차감)</span>
                        </label>
                        <label>
                            <input
                                type="radio"
                                name="post-type"
                                value="recommend"
                                checked={postClass === POST_CLASS.pin}
                                onChange={() => setPostClass(POST_CLASS.urgent)}
                            />
                            <span className="recommend">추천등록</span> <span>아이콘, 눈에띄는 색상, 게시판 최상단 7일간 노출홍보 (최상단 공지: 포인트 -100,000차감)</span>
                        </label>
                        <div className="post-type-note">
                            *필독 - 제목에 특수문자 사용, 카테고리에 맞지 않은 글작성, 공지사항을 위배한 글쓰기는 삭제 및 이용제한되실 수 있습니다.
                        </div>
                    </div>
                </div>
            </div>}

            <div className="write-board">
                <div className="form-group">
                    <textarea
                        placeholder="제목을 입력해주세요"
                        rows="1"
                        value={postTitle}
                        onChange={(e) => handleInputChange(e, setPostTitle)}
                    />
                </div>

                <div className="company-info row">
                    <div className="form-group half-width">
                        <label htmlFor="company-name">회사명</label>
                        <input
                            type="text"
                            placeholder="예: 마이테크"
                            value={postCompany}
                            onChange={(e) => handleInputChange(e, setPostCompany)}
                        />
                    </div>
                    <div className="form-group half-width">
                        <label htmlFor="contact-person">담당자명</label>
                        <input
                            type="text"
                            placeholder="예: 김태연"
                            value={contact}
                            onChange={(e) => handleInputChange(e, setContact)}
                        />
                    </div>
                </div>

                <div className="contact-info row">
                    <div className="form-group third-width">
                        <label htmlFor="phone">연락처</label>
                        <input
                            type="text"
                            placeholder="예: 010-0000-0000"
                            value={contactPhone}
                            onChange={(e) => handleInputChange(e, setContactPhone)}
                        />
                    </div>
                    <div className="form-group third-width">
                        <label htmlFor="email">이메일</label>
                        <input
                            type="text"
                            placeholder="예: email@example.com"
                            value={contactEmail}
                            onChange={(e) => handleInputChange(e, setContactEmail)}
                        />
                    </div>
                    <div className="form-group third-width">
                        <label htmlFor="website">카카오톡</label>
                        <input
                            type="text"
                            placeholder=""
                            value={contactKakao}
                            onChange={(e) => handleInputChange(e, setContactKakao)}
                        />
                    </div>
                </div>

                {/* <div className="select-category">
                    <div className="form-group2">
                        <label htmlFor="category">구분</label>
                    </div>
                    <select
                        value={postCategory}
                        onChange={(e) => handleInputChange(e, setPostCategory)}
                    >
                        <option value="response">통합대행</option>
                        <option value="advertisement">통합대행</option>
                    </select>
                </div> */}

                <div className="form-group">
                    <Editor data={content} onChange={handleEditorChange} />
                </div>
                <div className="submit-section">
                    <button className="submit-btn" onClick={handleSubmit}>
                        {postId ? '수 정' : '등 록'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PostWrite;
