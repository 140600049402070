import PinnedPost from '../post/PinnedPost';
import { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, Outlet, useParams } from 'react-router-dom';
import UserContext from '../../../context/UserContext';
import PostApi from '../../../api/PostApi';
import PageNavigation from '../../pageNavigation/PageNavigation';
import TimestampDisplay from '../../common/TimestampDisplay';

const PAGE_SIZE = 30

const UrlTr = ({post}) => {
    const navigate = useNavigate();
    const { postId = '' } = useParams();
    const handleTrClick = async () => {
        const isSamePost = parseInt(postId) === post.post_id;
        if (!isSamePost) {
            await PostApi.updateVisitCount(post.post_id);
            post.post_visit++;
            navigate(`/post/detail/${post.post_id}`);
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        
    }
    return (
        <tr key={post.post_id}
            onClick={handleTrClick}
            className={(postId && postId == post.post_id) ? 'on' : ''}
        >
            <td className="pc-only">{post.post_id}</td>
            <td>홍보/마케팅</td>
            <td>{post.post_title}</td>
            <td>대행</td>
            <td className="pc-only">
                <TimestampDisplay timestamp={post.post_reg_date}/>
            </td>
            <td className="pc-only">{post.post_visit}</td>
            <td>{post.post_user_id}</td>
        </tr>
    )
}

const Home = props => {
    const navigate = useNavigate();
    const location = useLocation();
    const { user } = useContext(UserContext);
    const [ searchInput, setSearchInput ] = useState('');
    const [ totalCnt, setTotalCnt ] = useState(0);
    const [ posts, setPosts ] = useState([]);
    const [ page, setPage ] = useState(1);

    const handleSearchInput = e => setSearchInput(e.target.value);
    
    const getPosts = async (props = {}) => {
        const { 
            newPage = page,
            newSize = PAGE_SIZE,
            newSearchKeyword = searchInput
        } = props;
        const rslt = await PostApi.getPosts('', newPage, newSize, false, null, newSearchKeyword);
        setPosts(rslt.posts);
    }
    
    const handleSearchInputKeyup = e => {
        if (e.which !== 13) return;
        getPosts(searchInput);
    }

    const handleSearchBtn = () => {
        getPosts(1, null, searchInput);
    }

    const handleWriteBtn = () => {
        if (!user.userId) return alert('로그인을 하셔야 합니다.')
        navigate('/post/write');
    }

    const getTotalCount = async () => {
        const rslt = await PostApi.getPostTotalCount();
        setTotalCnt(rslt.totalCnt);
    }

    const handlePageChage = newPage => {
		if (!newPage) return;
		setPage(newPage);
		getPosts({ newPage });
	}

    useEffect(() => {
        getTotalCount();
    }, []);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const reload = queryParams.get("reload");
        if (reload === 'true') {
            getTotalCount();
            getPosts();
        }
    }, [location.search])

    return (
        <div className="center-content">
            <Outlet /> 
            <div className="search-container">
                <div className="search-section">
                    <div className="input-container">
                        <i className="fas fa-search"></i>
                        <input type="text" placeholder="검색 키워드" 
                            value={searchInput} 
                            onChange={handleSearchInput} 
                            onKeyUp={handleSearchInputKeyup}
                        />
                    </div>
                    <button className="search-btn" onClick={handleSearchBtn}>검색</button>
                    <button className="write-btn" onClick={handleWriteBtn}>글쓰기</button>
                </div>
                <PinnedPost />
            </div>
                <div className="table-section">
                    <table>
                        <colgroup>
                            <col style={{width: '6%'}}/>
                            <col style={{width: '11%'}}/>
                            <col style={{width: 'auto'}}/>
                            <col style={{width: '6%'}}/>
                            <col style={{width: '11%'}}/>
                            <col style={{width: '6%'}}/>
                            <col style={{width: '11%'}}/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th className="pc-only">번호</th>
                                <th>카테고리</th>
                                <th className="tit_in">제목</th>
                                <th>분류</th>
                                <th className="pc-only">등록일</th>
                                <th className="pc-only">조회</th>
                                <th>작성자</th>
                            </tr>
                        </thead>
                        <tbody>
                            {posts.map(post => <UrlTr post={post} />)}
                        </tbody>
                    </table>
                    <PageNavigation totalCount={totalCnt} showCount={30} onChange={handlePageChage} />
                </div>
            </div>
    )
}

export default Home;