const PopupBase = props => {
    const { closed = () => {} } = props;
    const handleCloseBtn = () => {
        closed(false);
    }

    return (
        <div className="popup" style={{display: 'block'}}>
            <div className="popup-content">
                <span className="close" onClick={handleCloseBtn}>×</span>
                {props.children}
            </div>
        </div>
    )
}

export default PopupBase;