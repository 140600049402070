import { useState } from "react";

const defaultGroups = [
    {
        "groupId": 0,
        "groupNm": "전체"
    }
]

const SelectGroup = props => {
    const { groups, selectCallback = () => {} } = props;
    const [selectedGroup, setSelectedGroup] = useState(defaultGroups[0].value);

    const handleSelectChg = e => {
        setSelectedGroup(e.target.value);
        selectCallback(e.target.value);
    }

    return (
        <select className="select-basic" value={selectedGroup} onChange={handleSelectChg}>
            {[...defaultGroups, ...groups].map(({groupNm, groupId}) => <option key={groupId} value={groupId}>{groupNm}</option>)}
        </select>
    )
}

export default SelectGroup;