const MyPoint = () => {
    return (
        <div className="tab-content active">
            <div className="points-history">
                <div className="table-section">
                    <table>
                        <thead>
                            <tr>
                                <th>날짜</th>
                                <th>타입</th>
                                <th>사용 포인트</th>
                                <th>남은 포인트</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>2024-08-25 23:28:00</td>
                                <td>무슨무슨 타입</td>
                                <td>600,000</td>
                                <td>200,000</td>
                            </tr>
                                <tr>
                                <td>2024-08-25 23:28:00</td>
                                <td>무슨무슨 타입</td>
                                <td>600,000</td>
                                <td>200,000</td>
                            </tr>
                                <tr>
                                <td>2024-08-25 23:28:00</td>
                                <td>무슨무슨 타입</td>
                                <td>600,000</td>
                                <td>200,000</td>
                            </tr>
                                <tr>
                                <td>2024-08-25 23:28:00</td>
                                <td>무슨무슨 타입</td>
                                <td>600,000</td>
                                <td>200,000</td>
                            </tr>
                                <tr>
                                <td>2024-08-25 23:28:00</td>
                                <td>무슨무슨 타입</td>
                                <td>600,000</td>
                                <td>200,000</td>
                            </tr>
                                <tr>
                                <td>2024-08-25 23:28:00</td>
                                <td>무슨무슨 타입</td>
                                <td>600,000</td>
                                <td>200,000</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button className="page-btn first-page"><i className="fas fa-angle-double-left"></i></button>
                        <button className="page-btn prev-page"><i className="fas fa-angle-left"></i></button>
                        <button className="page-btn">1</button>
                        <button className="page-btn">2</button>
                        <button className="page-btn">3</button>
                        <button className="page-btn next-page"><i className="fas fa-angle-right"></i></button>
                        <button className="page-btn last-page"><i className="fas fa-angle-double-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
    )  
}

export default MyPoint;