const POST_CLASS = {
    basic: 'PCL001',
    bold: 'PCL002',
    urgent: 'PCL003',
    pin: 'PCL004'
}

const POST_CLASS_OPTIONS = [
    { value: POST_CLASS.basic, lable: ''},
    { value: POST_CLASS.bold, lable: ''},
    { value: POST_CLASS.urgent, lable: ''},
    { value: POST_CLASS.pin, lable: ''}
]

export { POST_CLASS };