const Banner = props => {
    const { className = '', items = [], children } = props;
    return (
        <div className={className}>
            {children}
            <div className="banner-placeholder">배너 이미지 1</div>
            <div className="banner-placeholder">배너 이미지 2</div>
            <div className="banner-placeholder">배너 이미지 3</div>
            <div className="banner-placeholder">배너 이미지 4</div>
            <div className="banner-placeholder">배너 이미지 5</div>
            <div className="banner-placeholder">배너 이미지 6</div>
            <div className="banner-placeholder">배너 이미지 7</div>
        </div>
    )
}

export default Banner;