import React, { useMemo } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function CustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
        return new CustomUploadAdapter(loader);
    };
}

class CustomUploadAdapter {
    constructor(loader) {
        this.loader = loader;
    }

    upload() {
        return this.loader.file
            .then(file => new Promise((resolve, reject) => {
                if (!file.type.startsWith("image/")) {
                    return reject("이미지 파일만 업로드 가능합니다.");
                }
                if (file.size > 1024 * 1024) { // 1MB = 1024 * 1024 bytes
                    return reject("이미지 크기는 1MB 이하로 제한됩니다.");
                }
                const reader = new FileReader();
                reader.onload = () => {
                    this.curImageCount++;
                    resolve({ default: reader.result })
                };
                reader.onerror = error => reject(error);
                reader.readAsDataURL(file); // 파일을 base64로 인코딩
            }));
    }

    abort() {
        // 이미지 업로드 중단 시 호출됩니다.
    }
}

const Editor = ({ data = '', onChange = () => {} }) => {
    
    return (
        <CKEditor
            editor={ClassicEditor}
            data={data}
            onChange={onChange}
            config={{
                extraPlugins: [CustomUploadAdapterPlugin],
            }}
        />
    );
};

export default Editor;
