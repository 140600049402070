import { useContext, useState, useEffect } from "react";
import UserContext from "../../../context/UserContext";

const MyInfo = () => {
    const { user } = useContext(UserContext);
    const [userInfo, setUserInfo] = useState({...user});
    console.log('userInfo>>>>>', userInfo);
    // useEffect(() => setUserInfo({...user}), [user]) ;
    return (
        <div className="tab-content active">
            <div className="user-details-container">
                <div className="user-details-form">
                    <div className="form-group">
                        <label for="username">아이디</label>
                        <input type="text" id="userId" value={userInfo.userId} disabled />
                    </div>
                    <div className="form-group">
                        <label for="name">이름</label>
                        <input type="text" id="userNm" value={userInfo.userNm} disabled />
                    </div>
                    <div className="form-group">
                        <label for="name">닉네임</label>
                        <input type="text" id="userNicknm" value={userInfo.userNicknm} />
                    </div>
                    <div className="form-group">
                        <label for="password">비밀번호</label>
                        <input type="password" id="userPw" value={userInfo.userPw} placeholder="비밀번호" />
                    </div>
                    <div className="form-group">
                        <label for="confirm-password">비밀번호 확인</label>
                        <input type="password" id="userPwConfirm" value={userInfo.userPwConfirm} placeholder="비밀번호 확인" />
                        <p className="input-hint">비밀번호는 8자리 이상 입력하세요.</p>
                    </div>
                    <div className="form-group">
                        <label for="phone">연락처</label>
                        <input type="text" id="userPhone" value={userInfo.userPhone} />
                    </div>
                    <button type="submit" className="submit-btn">수정하기</button>
                </div>
            </div>
        </div>
    )
}

export default MyInfo;