const MyGroup = () => {
    return (
        <div className="tab-content active">
            <div className="user-details-container">
                <div>
                    <input />
                    <button>추가하기</button>
                </div>
                <ul>
                    <li>
                        <div>
                            <span>그룹명</span>
                            <button>수정하기</button>
                            <button>삭제하기</button>
                        </div>
                    </li>
                    <li>
                        <div>
                            <span>그룹명</span>
                            <button>수정하기</button>
                            <button>삭제하기</button>
                        </div>
                    </li>
                    <li>
                        <div>
                            <span>그룹명</span>
                            <button>수정하기</button>
                            <button>삭제하기</button>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default MyGroup;