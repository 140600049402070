import Http from '../util/Http';

let CachedGroup = null;

class GroupApi {
    // 그룹 목록 조회
    static async getGroups() {
        try {
            if (CachedGroup) return CachedGroup;
            const res = await Http.get('/group');
            CachedGroup = res;
            return res || null;
        } catch (error) {
            console.error('Error fetching groups:', error);
            return null;
        }
    }

    // 그룹 생성
    static async createGroup(groupData) {
        try {
            const res = await Http.post('/group', groupData);
            CachedGroup = null;
            return res || null;
        } catch (error) {
            console.error('Error creating group:', error);
            return null;
        }
    }

    // 그룹 업데이트
    static async updateGroup(groupId, groupData) {
        try {
            const res = await Http.put(`/group/${groupId}`, groupData);
            return res || null;
        } catch (error) {
            console.error('Error updating group:', error);
            return null;
        }
    }

    // 그룹 삭제
    static async deleteGroup(groupId) {
        try {
            const res = await Http.delete(`/group/${groupId}`);
            CachedGroup = null;
            return res || null;
        } catch (error) {
            console.error('Error deleting group:', error);
            return null;
        }
    }
}

export default GroupApi;
