import { useState } from "react";
import SelectGroup from "../../group/SelectGroup";
import SlotApi from "../../../api/SlotApi";

const AddSlot = props => {
    const { addComplete = () => {}, groups = [] } = props;
    const [ keyword, setKeyword ] = useState('');
    const [ prodNm, setProdNm ] = useState('');
    const [ registerGroupId, setRegisterGroupId ] = useState(0);

    const handleKeywordInput = e => setKeyword(e.target.value);
    const handleProdNmInput = e => setProdNm(e.target.value);
    
    const handleRegisterBtn = async () => {
        const rslt = await SlotApi.createSlot({
            slotGroupId: registerGroupId || null,
            slotKeyword: keyword,
            slotTargetNm: prodNm
        });
        if (rslt && rslt.code === 'success') {
            console.log('addComplte~~~~~~~~~~~~~~~~~~~~~!');
            setKeyword('');
            setProdNm('');
            setRegisterGroupId(0);
            return addComplete();
        }
        alert(rslt.message);
    }

    return (
        <div className="search-section">
            <SelectGroup groups={groups} selectCallback={setRegisterGroupId} />
            <div className="input-icon">
                <i className="fas fa-search icon"></i>
                <input type="text" placeholder="검색 키워드" className="search-keyword" 
                    value={keyword} onChange={handleKeywordInput} 
                />
            </div>
            <input type="text" placeholder="페이지 URL 주소 입력" className="search-url"
                    value={prodNm} onChange={handleProdNmInput}
                />
            <button className="search-btn" onClick={handleRegisterBtn}>등록</button>
        </div>
    )
}

export default AddSlot;