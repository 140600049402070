import Http from "./Http";

class Payment {
    
    static readyToPay({prodId, isExtend}) {
        return new Promise((res, rej) => {
            Http.post('/order/ready', {prodId, isExtend}, result => {
                if(!result.login) {
                    alert(result.message);
                }else {
                    if(result.code === 'success') {
                        res(result.user);
                    }else {
                        const msg = result.message ? result.message : '중복된 주문이거나, 주문 정보가 올바르지 않습니다.';
                        alert(msg)
                        res(null);
                    }
                }
            }, error => {
                console.log('error', error);
                rej(error);
            })
        });
    }

    static openPaymentPage(data, user, completeUrl = '/order/complete') {
        console.log('?????????????', data.prodPrice, )
        const amount = Math.round(Number(data.prodPrice) * (100-Number(data.prodDisc)) * 0.01);
        console.log('amount>>>>>>>>>>>>>>"', amount);
        window.IMP.init("imp68255594");
        window.IMP.request_pay({
            pg : 'kcp',
            pay_method : 'card',
            merchant_uid : data.prodId + '_' + new Date().getTime(),
            name : data.prodTitle,
            amount,
            buyer_email : user.userEmail,
            buyer_name : user.userNm,
            buyer_tel : user.userPhone,
            buyer_addr : '',
            buyer_postcode : ''
        }, function(payResult) {
            payResult.isExtend = data.isExtend || false;
            if (payResult.success) {
                Http.post(completeUrl, payResult, result => {
                    alert(result.message);
                })
            } else {
                alert(payResult.error_msg);
            }
        });
    }

}

export default Payment;