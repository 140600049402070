import React, { useEffect } from 'react';
import { HashRouter as Router, Routes, Route, Outlet, useLocation } from 'react-router-dom';
import './css/default.css';
import './css/main_new.css';
import './css/reset.css';

import Header from './component/header/Header';
import Footer from './component/footer/Footer';
import Content from './component/content/Content';
import Banner from './component/banner/Banner';
import Shopping from './component/container/shopping/Shopping';
import Login from './component/container/login/Login';
import Home from './component/container/home/Home';
import Register from './component/container/user/Register';
import Place from './component/container/place/Place';
import FindInfo from './component/container/user/FindInfo';
import MyInfo from './component/container/user/MyInfo';
import Admin from './component/container/admin/Admin';
import LeftBanner from './component/banner/LeftBanner';
import RightSidebar from './component/sidebar/RightSidebar';
import PlaceRank from './component/container/rank/place/PlaceRank';
import Order from './component/container/order/Order';
import PostWrite from './component/container/post/PostWrite';
import PostDetail from './component/container/post/PostDetail';
import MyPage from './component/container/mypage/MyPage';

import { UserProvider } from './context/UserContext';

const buildNormalComponent = Comp => {
  return (
    <div className='contents'>
      <div className='main'>
        <LeftBanner />
          <Comp />
        <RightSidebar />
      </div>
    </div>
  )
}

const buildNoBannerComponent = Comp => {
  return (
    <div className='contents_login' style={{display: 'block'}}>
      <Comp />
    </div>
  )
}

function App() {
  return (
    <>
        <UserProvider>
          <Router>
              <Header />
              <Routes>
                <Route path="/" element={buildNormalComponent(Home)}>
                  <Route path="/post/detail/:postId" element={<PostDetail />} />  
                </Route>
                <Route path="/placeRank" element={buildNormalComponent(PlaceRank)} />
                <Route path="/placeOnce" element={buildNormalComponent(Place)} />
                <Route path="/shoppingRank" element={buildNormalComponent(PlaceRank)} />
                <Route path="/shoppingOnce" element={buildNormalComponent(Shopping)} />
                <Route path="/order" element={buildNormalComponent(Order)} />
                <Route path="/post/write" element={buildNormalComponent(PostWrite)} />
                <Route path="/post/write/:postId" element={buildNormalComponent(PostWrite)} />
                <Route path="/mypage/:tab" element={buildNormalComponent(MyPage)} />
                <Route path="/login" element={buildNoBannerComponent(Login)} />
                <Route path="/register" element={buildNoBannerComponent(Register)} />

              </Routes>   
          </Router>
        </UserProvider>
    </>
  );
}

export default App;

