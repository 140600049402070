import ChargePoint from "./ChargePoint";
import SlotCountChange from "./SlotCountChange";
import ExtendSlotExpireDate from "./ExtendSlotExpireDate";

const Order = () => {
    return (
        <div class="center-content">
            <ChargePoint />
            <SlotCountChange />
        </div>
    )
}

export default Order;