
import { useState, useEffect } from 'react';
import SelectGroup from '../../group/SelectGroup';
import GroupPop from '../../popup/GroupPop';
import SlotApi from '../../../api/SlotApi';
import GroupApi from '../../../api/GroupApi';
import RankTd from './RankTd';
import PageNavigation from '../../pageNavigation/PageNavigation';
import InputWithEnter from '../../common/InputWithEnter';
import AddSlot from './AddSlot';

const PAGE_VIEW_OPTIONS = [
	{ label: 20, value : 20 },
	{ label: 30, value : 30 },
	{ label: 50, value : 50 }
]

const RankBase = props => {
    const { notice } = props;
	const [ slots, setSlots ] = useState([]);
	const [ totalCnt, setTotalCnt ] = useState(0);
    const [ searchKeyword, setSearchKeyword ] = useState('');
    const [ isCreateGroupPopOpen, setIsCreateGorupPopOpen ] = useState(false);
	const [ size, setSize ] = useState(PAGE_VIEW_OPTIONS[0].value);
	const [ page, setPage ] = useState(1);
	const [groups, setGroups] = useState([]);

	const [ selectGroupId, setSelectGroupId ] = useState(0);

    const handleSearchKeywordInput = e => {
        setSearchKeyword(e.target.value);
	}

	const handleSearchKeyowrdKeydow = e => {

	}

	const handleCreateGroupPopOpen = () => {
		console.log('open pop~~~~~~~~~~~~~')
		setIsCreateGorupPopOpen(true);
	}

    const handleCreateGroupPopCloseBtn = isUpdated => {
        setIsCreateGorupPopOpen(false);
    }

	const handleSlotCheckbox = selectedSlot => {
		selectedSlot.isChecked = !selectedSlot.isChecked;
		setSlots([...slots]);
	}

	const handleAllCheckbox = () => {
		const newIsCheckedAll = !isCheckedAll();
		const newSlots = slots.map(slot => ({...slot, isChecked: newIsCheckedAll}));
		setSlots(newSlots);
	}

	const isCheckedAll = () => {
		if (slots.length === 0) return false;
		for (let i = 0; i < slots.length; i++) {
			if (!slots[i].isChecked) return false;
		}
		return true;
	}

	// 페이지 변경
	const handlePageChage = newPage => {
		if (!newPage) return;
		setPage(newPage);
		getSlots({ newPage });
	}

	// 뷰 카운트 변경
	const handleViweCountChange = e => {
		const newSize = e.target.value;
		setSize(newSize);
		getSlots({ newSize });
	}

	// 그룹 변경
	const handleSelectGroupChange = newGroupId => {
		setSelectGroupId(newGroupId);
		getTotalCount({ newGroupId });
		getSlots({ newGroupId });
	}

	// 검색어 변경
	const handleSearchClick = () => {
		getTotalCount({});
		getSlots({});
	}

	// 슬롯 삭제
	const handleDeleteBtn = async () => {
		const ids = slots.filter(slot => slot.isChecked).map(slot => slot.slotId);
		const rslt = await SlotApi.deleteSlots(ids);
		if (rslt && rslt.code === 'success') {
			if (size === ids.length) {
				handlePageChage(1);
			} else {
				setSlots(slots.filter(slot => !slot.isChecked));
				setTotalCnt(totalCnt - ids.length);
			}
		}
		else alert('삭제에 실패했습니다.');
	}

	const getSlots = (props = {}) => {
		const { 
			newPage = page, 
			newSize = size, 
			newSearchKeyword = searchKeyword, 
			newGroupId = selectGroupId 
		} = props;
		SlotApi.getSlots(newPage, newSize, newSearchKeyword, newGroupId ).then(setSlots);
	}

	const getTotalCount = (props = {}) => {
		const { 
			newSearchKeyword = searchKeyword, 
			newGroupId = selectGroupId 
		} = props;
		SlotApi.getSlotTotalCnt(newSearchKeyword, newGroupId)
			   .then(({totalCnt}) => setTotalCnt(totalCnt));
	}

	const handleAddComplete = () => {
		console.log('rankbase addcomplete~~~~');
		getSlots();
		setTotalCnt(totalCnt+1);
	}

	useEffect(() => {
		getTotalCount();
		GroupApi.getGroups().then(data => {
            if (!data) return;
            setGroups(data);
        });
	}, [])

    return (
        <div className="center-content">
			{notice()}
			<AddSlot groups={groups} addComplete={handleAddComplete} />
			<div className="table-section1 pc-table">
				<div className="table-controls">
					<div className="left-controls">
						<SelectGroup groups={groups} selectCallback={handleSelectGroupChange} />
						<span className="total-count">전체수 : {totalCnt}</span>
					</div>
					<div className="right-controls">
						<div className="input-icon">
							<i className="fas fa-search icon"></i>
							<InputWithEnter 
								type="text" 
								placeholder="검색어를 입력해주세요" 
								className="search-input" 
								value={searchKeyword}
								onChange={handleSearchKeywordInput} 
								enterCallback={handleSearchClick}
							/>
						</div>
						<button className="search-btn">검색</button>
						<select className="number-select" onChange={handleViweCountChange} value={size}>
							{PAGE_VIEW_OPTIONS.map(({ label, value }) => 
								<option value={value}>{label}개</option>
							)}
						</select>
					</div>
				</div>
				<div className="filter-options">
					<button className="filter-btn dark-btn">전체목록</button>
					<button className="filter-btn delete-btn" onClick={handleDeleteBtn}>선택삭제</button>
					<button className="filter-btn dark-btn" onClick={handleCreateGroupPopOpen}>그룹변경/생성</button>
					<button className="filter-btn dark-btn">엑셀다운</button>
					<button className="filter-btn dark-btn">검색요청</button>
					<button className="filter-btn branding-btn">브랜딩보고서</button>
				</div>
				{isCreateGroupPopOpen && <GroupPop closed={handleCreateGroupPopCloseBtn} />}
				<table className="custom-table">
					<thead>
						<tr>
							<th>
								<input 
									type="checkbox" 
									className="custom-checkbox" 
									checked={isCheckedAll()}
									onChange={handleAllCheckbox}
								/>
							</th>
							<th>그룹</th>
							<th>검색키워드</th>
							<th colspan="10">등록일 / 순위</th>
							<th>메모</th>
						</tr>
					</thead>
						{slots.map(slot => (
							<tbody key={slot.slotId}>
								<tr>
									<td>
										<input 
											type="checkbox" 
											className="custom-checkbox" 
											checked={slot.isChecked}
											onChange={() => handleSlotCheckbox(slot)} 
										/>
									</td>
									<td>{slot.groupNm}</td>
									<td>
										<p className="highlight">{slot.slotKeyword}</p>
										<p className="highlight2">{slot.slotTargetKey || ''}</p>
										<p className="highlight2">{slot.slotTargetNm}</p>
									</td>
									<RankTd ranks={slot.ranks} />
									<td>
										<div className="memo-content">
											<p>메모 내용이 여기에 표시됩니다!</p>
											<div className="memo-buttons">
												<button className="edit-btn">수정</button>
												<button className="complete-btn">완료</button>
											</div>
										</div>
									</td>
								</tr>
							</tbody>
						))}
				</table>
			</div>
			<PageNavigation totalCount={totalCnt} showCount={size} onChange={handlePageChage} />
		</div>
    )
}

const MobileTable = ({totalCnt}) => {
	return (
		<div className="table-section mobile-table">
			<div className="table-controls">
				<div className="left-controls">
					<select className="group-select">
						<option>전체그룹</option>
					</select>
					<span className="total-count">전체수 : {totalCnt}</span>
				</div>
				<div className="right-controls">
					<select className="filter-select">
						<option>업체명 또는 URL</option>
					</select>
					<div className="input-icon">
						<i className="fas fa-search icon"></i>
						<input type="text" placeholder="검색어를 입력해주세요" className="search-input" />
					</div>
					<button className="search-btn">검색</button>
					<select className="number-select">
						<option>50개</option>
					</select>
				</div>
			</div>

			<div className="filter-options">
				<button className="filter-btn dark-btn">전체목록</button>
				<button className="filter-btn delete-btn">선택삭제</button>
				<button className="filter-btn dark-btn" id="openPopup2">그룹변경/생성</button>
				<button className="filter-btn dark-btn">엑셀다운</button>
				<button className="filter-btn dark-btn">검색요청</button>
				<button className="filter-btn branding-btn">브랜딩보고서</button>
			</div>
			<div id="popupLayer2" className="popup hidden">
				<div className="popup-content">
					<span id="closePopup2" className="close">×</span>
					<div className="group-info">
						<select id="group-select-mobile">
							<option>기본 그룹</option>
							<option>그룹 1</option>
							<option>그룹 2</option>
						</select>
						<button className="modify-btn">수정</button>
					</div>
					<div className="group-create">
						<input type="text" id="group-name-mobile" placeholder="그룹명을 입력하세요" />
						<input type="text" id="group-memo-mobile" placeholder="메모를 입력하세요" />
						<button className="create-btn">생성</button>
					</div>
				</div>
			</div>
			<div className="mobile_table">
				<div className="mobile-row">
					<div className="mobile-header">그룹</div>
					<div className="mobile-content">기본</div>
				</div>
				<div className="mobile-row">
					<div className="mobile-header">검색키워드</div>
					<div className="mobile-content">
						<p className="highlight">평택피부과</p>
						<p className="highlight2">11885927</p>
						<p className="highlight2">강남뷰베스트의원</p>
					</div>
				</div>
				<div className="mobile-row">
					<div className="mobile-header">등록일 / 순위</div>
					<div className="mobile-content rank-content">
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
					</div>
				</div>
				<div className="mobile-row">
					<div className="mobile-header">메모</div>
					<div className="mobile-content memo-content">
						<p>메모 내용이 <br />여기에 표시됩니다.</p>
						<div className="memo-buttons">
							<button className="edit-btn">수정</button>
							<button className="complete-btn">완료</button>
						</div>
					</div>
				</div>
			</div>
			<div className="mobile_table">
				<div className="mobile-row">
					<div className="mobile-header">그룹</div>
					<div className="mobile-content">기본</div>
				</div>
				<div className="mobile-row">
					<div className="mobile-header">검색키워드</div>
					<div className="mobile-content">
						<p className="highlight">평택피부과</p>
						<p className="highlight2">11885927</p>
						<p className="highlight2">강남뷰베스트의원</p>
					</div>
				</div>
				<div className="mobile-row">
					<div className="mobile-header">등록일 / 순위</div>
					<div className="mobile-content rank-content">
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
						<p>09-12(목) <span className="rank-value">9위</span></p>
					</div>
				</div>
				<div className="mobile-row">
					<div className="mobile-header">메모</div>
					<div className="mobile-content memo-content">
						<p>메모 내용이 <br />여기에 표시됩니다.</p>
						<div className="memo-buttons">
							<button className="edit-btn">수정</button>
							<button className="complete-btn">완료</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}


export default RankBase;