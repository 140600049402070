import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import PostApi from '../../../api/PostApi';

const PinnedPost = props => {
    const navigate = useNavigate();
    const { postId } = useParams();
    const { className = 'search-results' } = props;
    const [ posts, setPosts ] = useState([]);

    const handleLiClick = async post_id => {
        const isSamePost = parseInt(postId) === post_id;
        if (!isSamePost) {
            await PostApi.updateVisitCount(post_id);
            navigate(`/post/detail/${post_id}`);
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }

    useEffect(() => {
        PostApi.getPinnedPosts().then(data => {
            setPosts(data.posts);
        });
    }, [])

    return (
        <div className={className}>
            <ul>
                {posts.map(post => (
                    <li key={post.post_id} 
                        className={postId == post.post_id ? 'on' : ''}
                        onClick={() => handleLiClick(post.post_id)}
                    >
                        <span className="recommendation">추</span> {post.post_title}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default PinnedPost;