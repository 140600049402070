import { useParams, useNavigate } from "react-router-dom";
import MyOrder from "./MyOrder";
import MyInfo from "./MyInfo";
import MyPoint from "./MyPoint";
import MyPost from "./MyPost";
import MyGroup from "./MyGroup";

const TabBtn = ({ label='', tabNm, currentTabNm}) => {
    const navigate = useNavigate();
    const handleTabClick = () => navigate(`/mypage/${tabNm}`);
    return <button className={`tab-btn ${currentTabNm === tabNm ? 'active' : ''}`}
        onClick={handleTabClick}
    >{label}</button>
}

const MyPage = () => {
    const { tab } = useParams();
    return (
        <div className="center-content">
            <div className="tab-menu">
                <TabBtn label="내 정보" tabNm="info" currentTabNm={tab} />
                <TabBtn label="그룹 관리" tabNm="group" currentTabNm={tab} />
                <TabBtn label="주문 내역" tabNm="order" currentTabNm={tab} />
                <TabBtn label="포인트 내역" tabNm="point" currentTabNm={tab} />
                <TabBtn label="게시글 내역" tabNm="post" currentTabNm={tab} />
            </div>
            {tab === 'info' && <MyInfo />}
            {tab === 'group' && <MyGroup />}
            {tab === 'order' && <MyOrder />}
            {tab === 'point' && <MyPoint />}
            {tab === 'post' && <MyPost />}
        </div>
    )
} 

export default MyPage;