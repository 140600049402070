import { Link, useNavigate } from "react-router-dom"

const LoginNavigater = () => {
    const navigate = useNavigate();
    const handleLoginBtn = () => {
        navigate('/login');
    }
    return (
        <div class="login-container pc-only">
            <p class="login-info">더 안전하고 편리하게 로그인 후 이용하세요</p>
            <button class="naver-login-btn" onClick={handleLoginBtn}>
                로그인
            </button>
            <div class="login-options">
                <Link to="/login">아이디 찾기</Link>
                <span>|</span>
                <Link to="/login">비밀번호 찾기</Link>
                <span>|</span>
                <Link to="/register">회원가입</Link>
            </div>
        </div>
    )
}

export default LoginNavigater;