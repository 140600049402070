import React, { useState, useEffect } from 'react';

const PAGES = 5;

const PageNavigation = props => {
    const { totalCount, showCount = 5, onChange = () => {} } = props;
    const [currentPage, setCurrentPage] = useState(1);
    
    const totalPages = Math.ceil(totalCount / showCount);

    const handleFirstPage = () => setCurrentPage(1);
    const handleLastPage = () => setCurrentPage(totalPages);
    const handlePrevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));
    const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));

    const getPageNumbers = () => {
        const pages = [];
        const startPage = Math.max(currentPage - 2, 1);
        const endPage = Math.min(startPage + PAGES - 1, totalPages);
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }
        return pages;
    };

    useEffect(() => {
        onChange(currentPage);
    }, [currentPage]);

    if (totalPages === 1) return '';

    return (
        <div className="pagination">

            {totalPages > 5 && <>
                <button className="page-btn first-page" onClick={handleFirstPage} disabled={currentPage === 1}>
                    <i className="fas fa-angle-double-left"></i>
                </button>
                <button className="page-btn prev-page" onClick={handlePrevPage} disabled={currentPage === 1}>
                    <i className="fas fa-angle-left"></i>
                </button>
            </>}
            {getPageNumbers().map(page => (
                <button
                    key={page}
                    className={`page-btn ${page === currentPage ? 'active' : ''}`}
                    onClick={() => setCurrentPage(page)}
                >{page}
                </button>
            ))}
            {totalPages > 5 && <>
                <button className="page-btn next-page" onClick={handleNextPage} disabled={currentPage === totalPages}>
                    <i className="fas fa-angle-right"></i>
                </button>
                <button className="page-btn last-page" onClick={handleLastPage} disabled={currentPage === totalPages}>
                    <i className="fas fa-angle-double-right"></i>
                </button>
            </>}
        </div>
    );
};

export default PageNavigation;

