const InputWithEnter = props => {
    const { onChange, enterCallback, className = '', type = 'text', placeholder = '', value= '' } = props;

    const handleOnKeyUp = e => {
        console.log('enter !!!!!!!!!!!!!', e);
        if (e.which === 13 && enterCallback) {
            enterCallback();
        }
    }   

    return <input className={className} onChange={onChange} onKeyUp={handleOnKeyUp} 
        type={type} placeholder={placeholder} value={value}
    />
}

export default InputWithEnter;